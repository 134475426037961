/* Toolstack.css */
.vscode-icon {
	color: #007acc; /* Replace with your desired color */
  }
  
  .linux-icon {
	color: #fcc624;
  }
  
  .windows-icon {
	color: #0078d6;
  }
  
  .postman-icon {
	color: #ff6c37;
  }
  
  .vercel-icon {
	background-color: #000000; /* Set the background color to match your background */
	color: #ffffff; /* Set the desired color for the icon */
  }
  
  .heroku-icon {
	color: #79589f; /* Update the color to a more vibrant shade */
  }
  
  .eclipse-icon {
	color: #7d569b; /* Update the color to a more vibrant shade */
  }
  
  .androidstudio-icon {
	color: #3ddc84;
  }
  