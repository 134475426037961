/* Techstack.css */
.flutter-icon {
	color: #02569b; /* Replace with your desired color */
  }
  
  .dart-icon {
	color: #0175c2;
  }
  
  .js-icon {
	color: #f0db4f;
  }
  
  .nodejs-icon {
	color: #8cc84b;
  }
  
  .react-icon {
	color: #61dafb;
  }
  
  .mongodb-icon {
	color: #13aa52;
  }
  
  .c-icon {
	color: #a8b9cc;
  }
  
  .git-icon {
	color: #f34f29;
  }
  
  .firebase-icon {
	color: #ffca28;
  }
  
  .python-icon {
	color: #3776ab;
  }
  
  .android-icon {
	color: #3ddc84;
  }
  
  .apple-icon {
	background-color: #000000; /* Set the background color to match your background */
	color: #ffffff; /* Set the desired color for the icon */
  }
  
  .database-icon {
	color: #336791;
  }
  
  .gcp-icon {
	color: #4285f4;
  }
  
  .java-icon {
	color: #007396;
  }
  
  .kotlin-icon {
	color: #0095d5;
  }
  